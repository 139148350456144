import { selectedSpaceAtom } from "@/atoms/spaces"
import { OwnerView } from "@/features/workspaces/components/OwnerView"
import { SupplierView } from "@/features/workspaces/components/SupplierView"
import { useRecoilValue } from "recoil"
import { meAtom } from "../atoms/users"

function DashboardPage() {
  const selectedSpace = useRecoilValue(selectedSpaceAtom)
  const user = useRecoilValue(meAtom)

  if (selectedSpace?.welcomeSnippet || !user?.is_employee) {
    return <SupplierView />
  }

  return <OwnerView />
}

export default DashboardPage
